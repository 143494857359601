<template>
  <!-- datos cliente -->
  <div>
    <div class="row mt-24">
      <div class="col-sm-4">
        <label>Nombres</label>
        <input
          type="text"
          class="form-control"
          v-validate="'required|alpha_spaces'"
          v-model="nombre"
          name="nombres"
        />
        <span
          class="text-danger span-placeholder"
          v-show="errors.has('nombres')"
          >Campo requerido, solo caracteres</span
        >
      </div>

      <div class="col-sm-4">
        <label>Apellido Paterno</label>
        <input
          type="text"
          class="form-control"
          v-validate="'required|alpha_spaces'"
          v-model="apellidoPaterno"
          name="apellidoPaterno"
        />

        <span
          class="text-danger span-placeholder"
          v-show="errors.has('apellidoPaterno')"
          >Campo requerido, solo caracteres</span
        >
      </div>

      <div class="col-sm-4">
        <label>Apellido Materno</label>
        <input
          type="text"
          v-validate="'required|alpha_spaces'"
          v-model="apellidoMaterno"
          name="apellidoMaterno"
          class="form-control"
        />

        <span
          class="text-danger span-placeholder"
          v-show="errors.has('apellidoMaterno')"
          >Campo requerido, solo caracteres</span
        >
      </div>
    </div>

    <div class="row mt-10">
      <div class="col-sm-4">
        <label>Correo</label>
        <input
          type="text"
          class="form-control"
          v-validate="'required|email'"
          label="Correo electrónico"
          v-model="email"
          name="email"
        />

        <span class="text-danger span-placeholder" v-show="errors.has('email')"
          >Campo requerido, debe ser un email válido</span
        >

        <span class="text-danger text-sm" v-if="emailDominio"
          >*No se permite ingresar correos con dominio de SOC</span
        >
      </div>

      <div class="col-sm-4">
        <label>Telefono</label>
        <input
          type="text"
          v-model="telefono"
          name="telefono"
          v-validate="'required|min:10|max:10'"
          v-on:keypress="isNumber($event)"
          class="form-control"
        />

        <span
          class="text-danger span-placeholder"
          v-show="errors.has('telefono')"
          >El campo debe tener 10 caracteres</span
        >
      </div>
    </div>

    <!-- to /Calculadora-producto -->
    <div class="row mt-32 mb-8">
      <div class="flex justify-end">
        <button
          @click="registrarSolicitante()"
          :disabled="!validaFormulario"
          type="button"
          class="btn btn-blue custom-btn-blue-simula w-64"
        >
          Siguiente
        </button>
      </div>
    </div>

    <!-- {{ caso }} -->
  </div>
</template>


<script>
export default {
  name: "CalculadoraCliente",
  components: {},
  props: {
    // caso: {
    //   type: Object,
    //   required: false,
    // },
  },
  data() {
    return {
      caso: {},
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      email: "",
      telefono: "",
      Broker: ''
    };
  },
  mounted() {
    this.getDestinoData();
    this.getClienteData();
    this.getApikey();
    // console.log("CreandoCalculadoraCliente", this.caso);
  },
  watch: {},
  computed: {
    emailDominio() {
      return this.email.includes("socasesores") ? true : false;
    },
    validaFormulario() {
      return (
        this.nombre != "" &&
        this.apellidoPaterno != "" &&
        this.apellidoMaterno != "" &&
        this.email != "" &&
        this.telefono.length == 10 &&
        !this.emailDominio
      );
    },
  },
  methods: {
    getClienteData() {
			// si existe datos en localstorage
			if (JSON.parse(localStorage.getItem("calculadoraCliente"))) {
				let dataLocalStorage = JSON.parse(localStorage.getItem("calculadoraCliente"));
				this.nombre = dataLocalStorage.nombre;
        this.apellidoPaterno = dataLocalStorage.apellidoPaterno;
        this.apellidoMaterno = dataLocalStorage.apellidoMaterno;
        this.email = dataLocalStorage.email;
        this.telefono = dataLocalStorage.telefono;
      }
    },
		getDestinoData() {
			// si existe datos en localstorage
			if (JSON.parse(localStorage.getItem("calculadoraDestino"))) {
				let dataLocalStorage = JSON.parse(localStorage.getItem("calculadoraDestino"));
				this.caso = dataLocalStorage;
      }
		},
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    getApikey() {
      if (!JSON.parse(localStorage.getItem("userInfo")).ApiKey) {
        this.$router.push("/login");
      } else {
        this.key = JSON.parse(localStorage.getItem("userInfo")).ApiKey;
        this.Broker = JSON.parse(localStorage.getItem("userInfo")).EMail;
      }      
    },
    registrarSolicitante() { // registrasolicitante -> registracaso
      // console.log( 'registrandoBroker' );
      // guardando data en localstorage
      let clienteData = {
        nombre: this.nombre,
        apellidoPaterno: this.apellidoPaterno,
        apellidoMaterno: this.apellidoMaterno,
        email: this.email,
        telefono: this.telefono,
      };
			localStorage.setItem('calculadoraCliente', JSON.stringify(clienteData)); // guarda info capturada en storage
      
      let self=this
      this.$vs.loading({
        container: '#cotizacion',
        scale: 0.6
      });

      let objRequestRegistraSolicitante = {
        strApiKey: this.key,
        strMetodo: 'RegistraSolicitante',
        objSolicitante: {
          Nombres: this.nombre,
          ApellidoPaterno: this.apellidoPaterno,
          ApellidoMaterno: this.apellidoMaterno,
          EMail: this.email,
          TelefonoCelular: this.telefono,
          FechaNacimiento: '1992-01-01',
          StateData: 0,
        },
        objSolicitanteExtra: {
					CheckReplication: true,
				}
      }

      this.$validator.validateAll().then(result => {
        if (result) {
          this.$axios
            .post('/', objRequestRegistraSolicitante, {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
              },
            })
            .then(response => {
              if (response.data.intEstatus == 0) {
                
                // si el caso id existe, el caso esta siendo editado, se actualiza
                if (localStorage.getItem('casoInmobiliario')) {
                  // console.log( 'Actualizando caso' );
                  this.actualizarCaso(localStorage.getItem('casoInmobiliario'));
                } else {
                  // registro caso nuevo
                  this.registrarCaso();
                }

              } else {
                this.$vs.notify({
                  title: 'Ocurrio un error en RegistraSolicitante',
                  text: response.data.strError,
                  color: 'danger',
                  position: 'top-right',
                });
              }
            })
            .catch(function (error) {
              self.$vs.notify({
                title:'Ocurrio un error de sistema',
                text:error,
                color:'danger',
                position:'top-right'
              });
            });
        } else {
          this.$vs.notify({
            title:'Ocurrio un error',
            text:'Datos incompletos',
            color:'danger',
            position:'top-right'
          });
          this.$vs.loading.close('#cotizacion > .con-vs-loading'); // quitar loading cuando hay error
        }
      })
    },
    registrarCaso() {
      let self = this;
      let objRequestRegistraCaso1 = {};

      // obtener id bancos
      let arrBancos = [];
      this.caso.bancos.forEach(banco => {
        arrBancos.push(banco.value);
      });

      if( this.caso.destino == '19') { // solo existe el destino 19 los que estaban originalmente
        objRequestRegistraCaso1 = {
          strApiKey: this.key,
          strMetodo: 'RegistraCaso',
          
          objCaso: {
            ValorInmueble: parseFloat(this.caso.valor.replace(/,/g,"")),
            SaldoInsoluto: parseFloat(this.caso.credito.replace(/,/g,"")),
            Plazos: [this.caso.plazo],
            Solicitante: this.email,
            Destino: this.caso.destino,
            Esquemas: this.caso.esquema,
            PlazoRestante: this.caso.plazoRestante,
            PagoMensualActual: this.caso.pagoMensual, //parseFloat(this.caso.mensualidad.replace(/,/g,"")),
            Bancos: arrBancos, // solo ids
            PerfilCliente: 2,
            Broker: this.Broker,
            VersionOrigen: 'Web Inmobiliaria Rápida',
          }
        }
      } else {
        objRequestRegistraCaso1 = {
          strApiKey: this.key,
          strMetodo: 'RegistraCaso',
          
          objCaso: {
            ValorInmueble: parseFloat(this.caso.valor.replace(/,/g,"")),
            MontoSolicitado: parseFloat(this.caso.credito.replace(/,/g,"")),
            Plazos: [this.caso.plazo],
            Solicitante: this.email,
            Destino: this.caso.destino,
            Esquemas: this.caso.esquema,
            Bancos: arrBancos, // solo ids
            PerfilCliente: 2,
            Broker: this.Broker,
            VersionOrigen: 'Web Inmobiliaria Rápida'
          }
        }
      }

      this.$axios.post('/', objRequestRegistraCaso1, {
        headers: {
          'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8',
        },
      })
      .then(response => {
        if (response.data.intEstatus == 0) {
          this.$vs.loading.close('#cotizacion > .con-vs-loading');
          localStorage.setItem('casoInmobiliario', response.data.objContenido.Id); // guardar id caso en localstorage
          
          localStorage.setItem('calculadoraRegresa', 'Producto'); // cambia ubicacion

          this.$router.push('/productos/' + response.data.objContenido.Id).catch(() => {});
        } else {
          this.$vs.notify({
            title:'Ocurrio un error en RegistraCaso',
            text:response.data.strError,
            color:'danger',
            position:'top-right'
          });
        }
      })
      .catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    actualizarCaso(idCaso) {

      // debug
      // console.log( 'actualizandid', idCaso );
      // this.$vs.loading.close('#cotizacion > .con-vs-loading');
      // return;

      let self = this;
      let objRequestRegistraCaso1 = {};

      // obtener id bancos
      let arrBancos = [];
      this.caso.bancos.forEach(banco => {
        arrBancos.push(banco.value);
      });

      if( this.caso.destino == '19') { // solo existe el destino 19 los que estaban originalmente
        objRequestRegistraCaso1 = {
          strApiKey: this.key,
          strMetodo: 'RegistraCaso',
          
          objCaso: {
            Id: idCaso,
            ValorInmueble: parseFloat(this.caso.valor.replace(/,/g,"")),
            SaldoInsoluto: parseFloat(this.caso.credito.replace(/,/g,"")),
            Plazos: [this.caso.plazo],
            Solicitante: this.email,
            Destino: this.caso.destino,
            Esquemas: this.caso.esquema,
            PlazoRestante: this.caso.plazoRestante,
            PagoMensualActual: this.caso.pagoMensual, //parseFloat(this.caso.mensualidad.replace(/,/g,"")),
            Bancos: arrBancos, // solo ids
            PerfilCliente: 2,
            Broker: this.Broker,
            VersionOrigen: 'Web Inmobiliaria Rápida',
          }
        }
      } else {
        objRequestRegistraCaso1 = {
          strApiKey: this.key,
          strMetodo: 'RegistraCaso',
          
          objCaso: {
            Id: idCaso,
            ValorInmueble: parseFloat(this.caso.valor.replace(/,/g,"")),
            MontoSolicitado: parseFloat(this.caso.credito.replace(/,/g,"")),
            Plazos: [this.caso.plazo],
            Solicitante: this.email,
            Destino: this.caso.destino,
            Esquemas: this.caso.esquema,
            Bancos: arrBancos, // solo ids
            PerfilCliente: 2,
            Broker: this.Broker,
            VersionOrigen: 'Web Inmobiliaria Rápida'
          }
        }
      }

      this.$axios.post('/', objRequestRegistraCaso1, {
        headers: {
          'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8',
        },
      })
      .then(response => {
        if (response.data.intEstatus == 0) {
          this.$vs.loading.close('#cotizacion > .con-vs-loading');
          localStorage.setItem('casoInmobiliario', response.data.objContenido.Id); // guardar id caso en localstorage
          this.$router.push('/productos/' + response.data.objContenido.Id).catch(() => {});

          localStorage.setItem('calculadoraRegresa', 'Producto'); // cambia ubicacion
          
        } else {
          this.$vs.notify({
            title:'Ocurrio un error en RegistraCaso',
            text:response.data.strError,
            color:'danger',
            position:'top-right'
          });
        }
      })
      .catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    }
  },
};
</script>

<style lang="css">
</style>